@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}


//Responsive Breakpoints
$breakpoints: (
        xxs: 420px,
        xs: 576px,
        sm: 780px,
        md: 992px,
        lg: 1200px,
        xl: 1440px
);

@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get(
                    $breakpoints,
                    $breakpoint
    ); // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn "Invalid breakpoint: #{$breakpoint}.";
  }
}

@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get(
                    $breakpoints,
                    $breakpoint
    ); // Write the media query.
    @media (min-width: ($breakpoint-value)) {
      @content;
    }
    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn "Invalid breakpoint: #{$breakpoint}.";
  }
}

@mixin keep-ratio($height, $width){
  &::before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: $height / $width * 100%;
  }

  &::after { /* to clear float */
    content: "";
    display: table;
    clear: both;
  }
}

@mixin computedVh($height){
  height: calc(var(--vh, 1vh) * #{$height}); // TRY THIS WAY
}