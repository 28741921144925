@charset "UTF-8";
/***************************
	TABLE OF CONTENT
	01 - Reset
	02 - Global styles
	03 - Typography
	04 - Color specifics
	05 - Layout
	06 - Buttons
	07 - Icons
	08 - Header
	09 - Home page
	10 - Advanced search
	11 - Booking steps
	12 - Custom search results
	13 - Main content
	14 - Destinations
	15 - Contact
	16 - Blog
	17 - Faq
	18 - Error page
	19 - Login, register, my account
	20 - Sidebar
	21 - Footer
	22 - Form elements
	23 - Preloader
	24 - Plugin styles
	25 - Print.css
	26 - Css media querries (responsive css)

****************************/
/* 01 RESET */
*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.color ::-moz-selection, .color::-moz-selection {
  background: #31303f;
}

.green ::-moz-selection, .color::-moz-selection {
  background: #22cc8c;
}

.color ::selection, .color::selection {
  background: #31303f;
}

.green ::selection, .color::selection {
  background: #22cc8c;
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

.center,
.center .wrap {
  text-align: center;
}

/* 04 COLOR SPECIFICS */
.black {
  background-color: #31303f !important;
}

.grey {
  background-color: #34394A !important;
}

.red {
  background-color: #34394A !important;
}

.white {
  background-color: #fff !important;
}

.btn.white {
  color: #31303f !important;
}

.btn.full {
  width: 100%;
}

.btn.color:hover, .btn.color:focus, .btn.white:hover, .btn.white:focus {
  background: #31303f !important;
  color: #fff !important;
}

.btn.red:hover, .btn.red:focus, .btn.red:hover, .btn.red:focus {
  background: #de2222 !important;
  color: #fff !important;
}

.btn.black:hover, .btn.black:focus, .cta .btn:hover, .cta .btn:focus {
  background: #fff !important;
  color: #31303f !important;
}

.more {
  font-weight: 600;
  padding-top: 2px;
  display: inline-block;
  position: relative;
  text-decoration: none;
}

.more:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  opacity: 0;
  -ms-opacity: 0;
  width: 0;
}

.more:hover:after,
.more:focus:after {
  opacity: 1;
  -ms-opacity: 1;
  width: 100%;
}

.back {
  background: #858585;
}

/* 07 ICONS */
.icon {
  font-size: 36px;
  display: inline-block;
  background: none !important;
}

.contact-data .icon {
  font-size: 26px;
}

.icon.black {
  color: #31303f;
}

.circle {
  float: left;
  width: 88px;
  height: 88px;
  border: 2px solid #34394A;
  margin: 0 20px 30px 0;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  border-radius: 45px;
  text-align: center;
  line-height: 103px;
}

.circle .icon {
  color: #34394A;
}

/* 08 HEADER */
.header {
  float: left;
  width: 100%;
  background: #fff;
  height: 80px;
}

.header > .wrap {
  display: table;
  height: 80px;
}

.logo {
  display: table-cell;
  vertical-align: middle;
  padding: 5px 0;
}

.main-nav {
  font-size: 14px;
  text-transform: uppercase;
  position: relative;
}

.main-nav ul > li {
  float: left;
  display: block;
  padding: 30px 0 30px 30px;
  position: static;
}

.main-nav ul > li > a {
  color: #858585;
  border-radius: 0;
  position: relative;
}

.main-nav ul > li.active > a,
.main-nav ul > li.active > a:hover,
.main-nav ul > li.active > a:focus,
.main-nav ul > li:hover > a,
.main-nav ul > li:focus > a {
  color: #ed6f14;
}

.main-nav li:hover > ul {
  display: block;
}

.main-nav ul ul {
  display: none;
  position: absolute;
  top: 50px;
  margin-left: -10px;
  min-width: 200px;
  background: #fff;
  -webkit-box-shadow: 4px 6px 8px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 4px 6px 8px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 4px 6px 8px 0 rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
  z-index: 9999;
  -webkit-transition: all 0.3s ease 0.15s;
  -moz-transition: all 0.3s ease 0.15s;
  -o-transition: all 0.3s ease 0.15s;
  -ms-transition: all 0.3s ease 0.15s;
  transition: all 0.3s ease 0.15s;
}

.main-nav ul ul li {
  padding: 0;
}

.main-nav ul ul li a {
  display: block;
  color: #31303f;
  font-size: 13px;
  padding: 9px 15px 7px;
}

.main-nav ul ul li:hover a {
  color: #fff;
  background: #31303f;
}

.main-nav ul ul li:last-child a {
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
}

.main-nav ul ul li {
  float: none;
  position: relative;
}

.main-nav ul ul ul {
  position: absolute;
  left: 100%;
  top: 0;
  margin-left: 0;
}

.main-nav li:hover > div {
  display: block;
}

.main-nav li > div {
  display: none;
  position: absolute;
  width: 1170px;
  padding: 15px 0 30px;
  top: 100%;
  right: 0;
  z-index: 999;
  background: #fff;
  -webkit-box-shadow: 4px 6px 8px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 4px 6px 8px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 4px 6px 8px 0 rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
  -webkit-transition: all 0.3s ease 0.15s;
  -moz-transition: all 0.3s ease 0.15s;
  -o-transition: all 0.3s ease 0.15s;
  -ms-transition: all 0.3s ease 0.15s;
  transition: all 0.3s ease 0.15s;
}

.main-nav li > div div {
  position: static;
  display: inline-block;
  border-right: 1px solid #DFDFD0;
  padding: 0 30px;
  min-height: 120px;
}

.main-nav li > div div:last-of-type {
  border: none;
}

.main-nav li > div h2 {
  font-size: 15px;
  padding: 0 0 10px;
}

.main-nav li > div ul {
  display: block;
  margin: 0;
  position: static;
  background: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.main-nav li > div ul li a {
  padding: 0 0 5px;
  color: #858585;
}

.main-nav li > div ul li:hover a {
  background: none;
  color: #31303f;
}

/* 09 HOME PAGE */
.home .header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
}

.home .main {
  padding: 0;
}

.home .advanced-search {
  margin: 0;
}

.services {
  float: left;
  width: 100%;
}

.services.boxed article {
  padding: 0;
}

.services.boxed .details {
  text-align: left;
  padding: 25px 30px;
  border-right: 1px solid #DFDFD0;
}

.services.boxed article:last-child .details {
  border: none;
}

.services.boxed .details h4 {
  text-transform: uppercase;
}

.services.boxed .details h4 a {
  color: #31303f;
}

.services.iconic {
  padding: 68px 0 30px;
}

.services.iconic h3 {
  text-transform: uppercase;
  font-size: 17px;
  padding: 0 0 10px;
}

.partners {
  float: left;
  width: 100%;
  padding: 48px 0 30px;
}

.partners h2 {
  text-transform: uppercase;
  font-size: 30px;
  padding: 0 0 40px;
}

.testimonials h6 {
  text-transform: uppercase;
  position: relative;
  font-size: 39px;
  color: #fff;
}

.testimonials h6:before {
  content: "";
  width: 41px;
  height: 29px;
}

.testimonials p {
  padding: 0 20% 30px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.testimonials .meta {
  font-size: 16px;
}

.testimonials.white h6,
.testimonials.white p {
  color: #31303f;
}

/* 10 ADVANCED SEARCH */
.advanced-search {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  padding: 35px 0 28px;
  margin: 0 0 35px;
  border-bottom: 1px solid #fff;
}

.advanced-search h3 {
  font-size: 20px;
  margin: 0 0 35px;
}

.advanced-search .f-row {
  margin: 0 0 30px;
  padding: 0 0 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.3);
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.3);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.3);
}

.advanced-search .f-row:last-child {
  margin: 0;
  padding: 0;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.advanced-search .form-group {
  float: left;
  vertical-align: middle;
}

.advanced-search .form-group:first-of-type label,
.advanced-search .f-row:last-child label {
  padding-left: 0;
}

.advanced-search .form-group label {
  float: left;
  color: #fff;
  font-size: 15px;
}

.advanced-search .form-group input {
  float: right;
  border-color: #fff;
  color: #31303f;
}

.advanced-search .form-group input:focus,
.advanced-search .form-group .radio span {
  border-color: #fff;
}

.advanced-search .right {
  width: 23%;
}

.advanced-search .btn {
  width: 100%;
}

.form-group div.selector {
  border-color: #fff;
  height: 47px;
  line-height: 46px;
  color: #31303f;
}

.form-group div.selector:focus {
  border-color: #fff;
}

.form-group div.selector span {
  height: 47px;
  line-height: 46px;
}

.form-group div.selector span:before {
  border-color: #31303f transparent transparent transparent;
}

.form-group.spinner {
  width: 35.1%;
}

.form-group.spinner label {
  line-height: 47px;
  padding: 0;
}

.form-group.spinner input {
  width: 16%;
}

.form-group.radios {
  padding: 11px 0 0 5.8%;
  width: 28.9%;
}

.form-group.radios > div {
  float: left;
}

.form-group.radios > div:last-of-type {
  float: right;
}

.form-group.radios label {
  line-height: 25px;
  padding: 0;
}

.ui-datepicker {
  z-index: 9999 !important;
  padding: 5px;
  -webkit-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
}

.ui-datepicker .ui-widget-header {
  font-weight: normal;
}

.ui-datepicker table {
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.ui-datepicker th {
  font-size: 13px;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

.ui-datepicker td {
  text-align: center;
  padding: 0 !important;
  border: 1px solid #dfdfd0 !important;
}

.ui-datepicker td a,
.ui-datepicker td span {
  text-align: center;
  border: none !important;
  padding: 6px !important;
}

.ui-datepicker-buttonpane button {
  font-size: 13px;
  padding: 5px;
  margin: 9px 0 5px;
  background: #ed6f14 !important;
  color: #fff !important;
  border-color: #ed6f14 !important;
}

.ui-datepicker {
  z-index: 99999 !important;
}

.ui-datepicker .ui-button {
  background: #31303f !important;
  border: none !important;
}

.ui-widget .ui-slider-handle {
  background: #31303f;
  border-color: #31303f;
}

.ui_tpicker_unit_hide {
  display: none !important;
}

/* 11 BOOKING STEPS */
.output {
  color: #fff;
  padding: 30px 0;
  float: left;
  width: 100%;
  margin: 0 0 35px;
  border-bottom: 1px solid #fff;
}

.output p {
  font-size: 18px;
  padding: 0 0 3px;
  text-transform: uppercase;
}

.output p small {
  font-size: 15px;
  text-transform: none;
}

.output .wrap div {
  float: left;
  padding: 10px 0 2px;
}

.twoway .wrap div {
  width: 50%;
}

.twoway .wrap div:last-child {
  padding-left: 30px;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: -1px 0 0 rgba(255, 255, 255, 0.3);
  -moz-box-shadow: -1px 0 0 rgba(255, 255, 255, 0.3);
  box-shadow: -1px 0 0 rgba(255, 255, 255, 0.3);
}

.data th,
.data td {
  text-align: center;
  width: 16%;
}

.data th:first-child,
.data td:first-child {
  text-align: left;
  width: 52%;
}

.data i {
  width: 100%;
  display: inline-block;
  color: #858585;
  text-transform: none;
}

.sidebar .widget .summary h5 {
  padding: 0 0 13px;
}

.summary div {
  padding: 17px 20px 12px;
  float: left;
  width: 100%;
  border-bottom: 1px solid #DFDFD0;
}

.summary div:last-of-type {
  border: none;
}

.summary dl {
  font-size: 13px;
  text-transform: uppercase;
  float: left;
  width: 100%;
}

.summary dt {
  float: left;
  color: #858585;
  width: 32%;
  padding: 0 0 6px;
}

.summary dd {
  float: left;
  width: 68%;
  padding: 0 0 6px;
}

.total {
  background: #31303f;
  padding: 12px 20px;
  color: #fff;
  display: table;
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
}

.total dt {
  font-size: 15px;
  color: #fff;
  padding: 0;
  display: table-cell;
  vertical-align: baseline;
  float: none;
}

.total dd {
  font-size: 21px;
  padding: 0;
  display: table-cell;
  vertical-align: baseline;
  float: none;
}

.readonly {
  position: relative;
}

.readonly h3 {
  padding: 20px 0;
  margin-top: 10px;
  display: inline-block;
  width: 100%;
  border-top: 1px solid #DFDFD0;
}

.readonly h3:first-of-type {
  padding-top: 0;
  margin: 0;
  border: none;
}

.readonly .f-row {
  padding: 0 0 12px;
  text-transform: uppercase;
  font-size: 13px;
}

.readonly .f-row .one-fourth {
  color: #858585;
}

/* 12 CUSTOM SEARCH RESULTS */
.results {
  float: left;
  width: 100%;
  margin: 10px 0 5px;
}

.result {
  float: left;
  width: 100%;
  background: #fff;
  color: #34394A;
  overflow: hidden;
  -webkit-box-shadow: inset 0 0 0 1px #DFDFD0;
  -moz-box-shadow: inset 0 0 0 1px #DFDFD0;
  box-shadow: inset 0 0 0 1px #DFDFD0;
  margin: 0 0 30px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.result > div {
  padding: 0;
}

.result .one-fourth {
  width: 23%;
  position: relative;
}

.result img {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  padding: 1px 0 0 1px;
  max-height: 99%;
}

.result .one-half {
  width: 54%;
  -webkit-box-shadow: 1px 0 0 0 #DFDFD0 inset, -1px 0 0 0 #DFDFD0 inset;
  -moz-box-shadow: 1px 0 0 0 #DFDFD0 inset, -1px 0 0 0 #DFDFD0 inset;
  box-shadow: 1px 0 0 0 #DFDFD0 inset, -1px 0 0 0 #DFDFD0 inset;
}

.result .one-half h3 {
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 1px solid #DFDFD0;
  width: 100%;
  padding: 22px 30px 20px;
  position: relative;
}

.result .trigger {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  color: #fff;
  font-size: 15px;
  text-align: center;
  line-height: 20px;
  font-weight: bold;
  position: absolute;
  top: 35%;
  right: 25px;
  z-index: 2;
}

.result ul {
  margin: 0;
  display: table;
  width: 100%;
  height: 132px;
}

.result li:before {
  display: none;
}

.result li {
  width: 33.333%;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  padding: 0;
}

.result li:nth-child(2n) {
  -webkit-box-shadow: 1px 0 0 0 #DFDFD0 inset, -1px 0 0 0 #DFDFD0 inset;
  -moz-box-shadow: 1px 0 0 0 #DFDFD0 inset, -1px 0 0 0 #DFDFD0 inset;
  box-shadow: 1px 0 0 0 #DFDFD0 inset, -1px 0 0 0 #DFDFD0 inset;
}

.result li .icon {
  margin: 0 0 10px;
}

.result li p {
  padding: 0;
  line-height: 1.2;
}

.result .one-fourth > div {
  padding: 25px 30px 0 30px;
  height: 100%;
  display: inline-block;
  width: 100%;
  position: relative;
}

.result .price {
  font-size: 37px;
  line-height: 1.2;
}

.result .price small {
  font-size: 20px;
}

.result .meta {
  font-size: 15px;
  color: #34394A;
}

.result .btn {
  width: 100%;
  float: left;
  margin: 27px 0 0;
}

.result .full-width {
  border-top: 1px solid #DFDFD0;
  padding: 25px 50px 15px 30px;
  position: relative;
  background: #fff;
  border-bottom: 1px solid #DFDFD0;
  -webkit-box-shadow: 1px 0 0 0 #DFDFD0 inset, -1px 0 0 0 #DFDFD0 inset;
  -moz-box-shadow: 1px 0 0 0 #DFDFD0 inset, -1px 0 0 0 #DFDFD0 inset;
  box-shadow: 1px 0 0 0 #DFDFD0 inset, -1px 0 0 0 #DFDFD0 inset;
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
}

.result .close {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  color: #fff;
  font-size: 15px;
  text-align: center;
  line-height: 17px;
  text-indent: 0.04em;
  font-weight: bold;
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 2;
}

/* 13 MAIN CONTENT */
.main {
  float: left;
  width: 100%;
  min-height: 600px;
  padding: 0 0 105px;
  overflow: hidden;
  max-width: 100%;
}

.site-title {
  float: left;
  width: 100%;
  border-bottom: 1px solid #fff;
  margin: 0 0 35px;
}

.site-title .container {
  height: 100px;
  display: table;
  color: #fff;
  width: 100%;
}

.site-title h1 {
  display: table-cell;
  vertical-align: middle;
}

.breadcrumbs {
  float: right;
  padding: 40px 0 0;
  font-weight: 500;
}

.breadcrumbs li {
  float: left;
  padding: 0;
}

.breadcrumbs a {
  position: relative;
  color: #fff;
}

.breadcrumbs li a:after {
  font-weight: 400;
  padding: 0 8px;
  color: #fff;
}

.breadcrumbs a:hover,
.breadcrumbs a:focus {
  color: #31303f;
}

.pager {
  float: left;
  width: 100%;
  padding: 29px 0 0;
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.6);
  -webkit-box-shadow: 0 -1px 0 #DFDFD0;
  -moz-box-shadow: 0 -1px 0 #DFDFD0;
  box-shadow: 0 -1px 0 #DFDFD0;
}

.pager a {
  display: inline-block;
  margin: 0 7.5px 15px;
  width: 47px;
  height: 47px;
  line-height: 47px;
  background: #DFDFD0;
  color: #31303f;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.pager .current {
  color: #fff;
}

.pager a:hover,
.pager a:focus {
  color: #fff;
}

.overlay {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  -ms-opacity: 0;
  z-index: 20;
  background: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}

.overlay a.expand {
  border: 2px solid #fff;
  text-align: center;
  color: #fff;
  line-height: 60px;
  font-weight: 400;
  font-size: 30px;
  display: block;
  position: absolute;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.entry-featured:hover .overlay,
.featured-image:hover .overlay {
  opacity: 1;
  -ms-opacity: 1;
}

.entry-featured:hover a.expand,
.featured-image:hover a.expand {
  width: 60px;
  height: 60px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

.entry-featured a.expand:after,
.featured-image a.expand:after {
  display: none;
}

table {
  margin: 0 0 15px;
  width: 100%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  text-transform: uppercase;
  border-spacing: 0;
  border-left: 1px solid #DFDFD0;
}

table th {
  background: #31303f;
  border-right: 1px solid rgba(0, 0, 0, 0.13);
  color: #fff;
  padding: 17px 20px 16px;
  text-align: left;
  font-weight: 400;
}

table td {
  background: #fff;
  color: #31303f;
  padding: 17px 20px 16px;
  font-size: 14px;
  border-right: 1px solid #DFDFD0;
  border-bottom: 1px solid #DFDFD0;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

table td a,
table.hover a {
  color: #31303f;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}

table th:first-child {
  -webkit-border-radius: 3px 0 0 0;
  -moz-border-radius: 3px 0 0 0;
  border-radius: 3px 0 0 0;
}

table th:last-child {
  -webkit-border-radius: 0 3px 0 0;
  -moz-border-radius: 0 3px 0 0;
  border-radius: 0 3px 0 0;
}

table tr:last-child {
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
}

table tr:last-child td:first-child {
  -webkit-border-radius: 0 0 0 3px;
  -moz-border-radius: 0 0 0 3px;
  border-radius: 0 0 0 3px;
}

table tr:last-child td:last-child {
  -webkit-border-radius: 0 0 3px 0;
  -moz-border-radius: 0 0 3px 0;
  border-radius: 0 0 3px 0;
}

table.hover tr:hover td {
  color: #fff;
  border-color: rgba(0, 0, 0, 0.13);
}

table.hover tr:hover td a {
  color: #fff;
}

table.responsive {
  overflow-x: auto;
  display: block;
}

table.responsive thead,
table.responsive tbody,
table.responsive tfoot {
  width: 100%;
}

.offset {
  margin-top: 65px;
}

.textongrey p {
  font-weight: 500;
  line-height: 1.5;
  padding: 0 0 20px;
}

.cta {
  float: left;
  width: 100%;
}

.cta .wrap {
  display: table;
  height: 130px;
}

.cta p {
  font-size: 23px;
  font-weight: 500;
  display: table-cell;
  vertical-align: middle;
  padding: 0 15px 0 0;
  color: #fff;
}

.cta .btn {
  margin-top: 30px;
}

/* 14 DESTINATIIONS */
.tabs {
  float: left;
  width: 100%;
  margin: 0 0 30px;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}

.tabs ul {
  display: block;
  width: 100%;
}

.tabs li {
  float: left;
  display: block;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  text-transform: uppercase;
  padding: 0;
}

.tabs li:last-child {
  border: none;
}

.tabs li:first-child a {
  -webkit-border-radius: 3px 0 0 0;
  -moz-border-radius: 3px 0 0 0;
  border-radius: 3px 0 0 0;
}

.tabs li:last-child a {
  -webkit-border-radius: 0 3px 0 0;
  -moz-border-radius: 0 3px 0 0;
  border-radius: 0 3px 0 0;
}

.tabs li a {
  float: left;
  color: #fff;
  padding: 17px 20px 16px;
  width: 100%;
  position: relative;
  white-space: nowrap;
}

.tabs li.active a:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 45%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
}

.tabs.one li {
  width: 100%;
}

.tabs.two li {
  width: 50%;
}

.tabs.three li {
  width: 33.333%;
}

.tabs.four li {
  width: 25%;
}

.tabs.five li {
  width: 20%;
}

.tabs.six li {
  width: 16.667%;
}

.tab-content article {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.tab-content article img {
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}

.description {
  float: left;
  width: 100%;
  padding: 17px 20px 20px;
  background: #fff;
  color: #34394A;
  -webkit-box-shadow: inset 0 0 0 1px #DFDFD0;
  -moz-box-shadow: inset 0 0 0 1px #DFDFD0;
  box-shadow: inset 0 0 0 1px #DFDFD0;
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
}

.description h3 {
  font-size: 18px;
  padding: 0 0 12px;
}

.description h3 a:hover {
  text-decoration: none;
}

.description div {
  border-bottom: 1px solid #DFDFD0;
  margin: 0 0 14px;
  padding: 0 0 14px;
}

.description p {
  padding: 0 0 4px;
  font-size: 14px;
}

.description div a {
  color: #34394A;
}

.description div a:hover,
.description div a:focus {
  text-decoration: underline;
}

.keyvisual {
  float: left;
  width: 100%;
  position: relative;
  height: 600px;
  overflow: hidden;
  margin-top: -36px;
}

.keyvisual img {
  position: absolute;
  top: -20%;
  left: 0;
}

.microlocations {
  float: left;
  width: 100%;
  padding: 35px 0 6px;
  position: relative;
  margin-top: -105px;
}

.microlocations h3 {
  padding: 0 0 27px;
}

.microlocations p {
  padding: 0 0 4px;
}

.microlocations p a {
  color: #34394A;
}

.microlocations p a:hover,
.microlocations p a:focus {
  text-decoration: underline;
}

/* 15 CONTACT */
.gmap {
  float: left;
  width: 100%;
  height: 450px;
  margin: -36px 0 35px;
  border-bottom: 1px solid #fff;
}

.gmap img {
  max-width: none;
}

.gmap label {
  width: auto;
  display: inline;
}

.infoBox {
  text-align: left;
  font-size: 15px;
  width: 270px;
  max-width: 100%;
  padding: 27px 30px 29px;
  margin-top: -80px;
  color: #fff;
  background: #36394A;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.infoBox span {
  font-size: 17px;
}

.infoBox:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 15px;
  border-color: #36394A transparent transparent transparent;
  position: absolute;
  bottom: -15px;
  left: 30px;
}

/* 16 BLOG */
.hentry {
  float: left;
  width: 100%;
  background: #fff;
  color: #34394A;
  -webkit-box-shadow: inset 0 0 0 1px #DFDFD0;
  -moz-box-shadow: inset 0 0 0 1px #DFDFD0;
  box-shadow: inset 0 0 0 1px #DFDFD0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.hentry .entry-featured {
  float: left;
  width: 33.33%;
  position: relative;
  overflow: hidden;
}

.hentry .entry-featured img {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}

.hentry .entry-content {
  float: left;
  width: 66.66%;
  padding: 25px;
}

.hentry .entry-content h2 {
  font-size: 19px;
  padding: 0 0 16px;
  text-transform: uppercase;
}

.hentry .entry-content h2 a {
  color: #34394A;
}

.one-half .entry-featured, .one-half .entry-content {
  width: 100%;
}

.one-half .entry-featured img {
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}

.hentry.single {
  margin-bottom: 30px;
}

.single .entry-featured {
  width: 100%;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}

.single .entry-featured img {
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}

.three-fourth .single .entry-featured {
  height: 450px;
}

.one-half .single .entry-featured {
  height: 300px;
}

.three-fourth .single .entry-featured img,
.one-half .single .entry-featured img {
  position: absolute;
  top: -30%;
  left: 0;
}

.single .entry-content {
  width: 100%;
  position: relative;
  padding: 20px 25px;
}

.single .entry-content h2 {
  font-size: 25px;
  text-transform: none;
  padding: 0 0 12px;
}

.lead,
.textongrey .lead {
  font-size: 16px;
  font-weight: 600;
}

.single p {
  line-height: 1.5;
}

.single a {
  position: relative;
  font-weight: 600;
}

.single a:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  opacity: 0;
  -ms-opacity: 0;
  width: 0;
}

.single a:hover:after,
.single a:focus:after {
  opacity: 1;
  -ms-opacity: 1;
  width: 100%;
}

blockquote {
  position: relative;
  background: #ededdc;
  margin: 6px 0 20px;
  padding: 20px 25px 20px 70px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

blockquote i {
  position: absolute;
  top: 25px;
  left: 27px;
}

.comments {
  float: left;
  width: 100%;
  margin: -2px 0 30px;
  border-bottom: 1px solid #DFDFD0;
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
}

.comments h3 {
  padding: 0 0 30px;
  color: #31303f;
}

.comment-list {
  margin: 0;
}

.comments li {
  list-style: none;
  float: left;
  width: 100%;
  color: #34394A;
  margin: 0 0 30px;
  padding: 0 0 0 100px;
  position: relative;
}

.comments .avatar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100px;
}

.comments .avatar img {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  border: 1px solid #DFDFD0;
}

.comments .depth-2 {
  padding-left: 200px;
  margin-left: 0;
  width: auto;
}

.depth-2 .avatar {
  left: 100px;
}

.comments .depth-3 {
  padding-left: 300px;
  margin-left: 0;
  width: auto;
}

.depth-3 .avatar {
  left: 200px;
}

.comment-box {
  float: left;
  width: 100%;
  background: #fff;
  min-height: 100px;
  padding: 15px 15px 0;
  border: 1px solid #DFDFD0;
  border-left: 0;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}

.comment-box .meta {
  color: #999;
  padding: 0 0 8px;
}

.comment-box a {
  color: #F2716A;
}

.meta {
  font-size: 13px;
  color: #858585;
}

.meta strong {
  font-size: 15px;
  font-weight: 400;
  color: #31303f;
  padding: 0 3px 0 0;
}

.comment-text {
  float: left;
}

.comment-text p {
  font-size: 14px;
  padding: 0 0 14px;
  max-width: 86%;
}

.comments .btn {
  float: right;
  position: absolute;
  top: 15px;
  right: 15px;
}

.box {
  float: left;
  width: 100%;
  background: #fff;
  padding: 20px 25px 25px;
  color: #34394A;
  -webkit-box-shadow: inset 0 0 0 1px #DFDFD0;
  -moz-box-shadow: inset 0 0 0 1px #DFDFD0;
  box-shadow: inset 0 0 0 1px #DFDFD0;
  margin: 0 0 30px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.comment-respond h3 {
  padding: 0 0 20px;
}

.comment-respond label {
  color: #858585;
}

/* 17 FAQ */
.faqs {
  background: #fff;
  border: 1px solid #DFDFD0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  display: inline-block;
  width: 100%;
  margin: 22px 0 33px;
}

.faqs dt {
  padding: 25px;
  color: #858585;
  font: 600 17px "Raleway", Arial, Helvetica, sans-serif;
  border-top: 1px solid #DFDFD0;
  cursor: pointer;
  position: relative;
}

.faqs dt:after {
  content: "+";
  position: absolute;
  top: 12px;
  right: 25px;
  font: normal 40px "Times New Roman", serif;
  color: #ccc;
}

.faqs .expanded:after {
  content: "–";
  top: 7px;
}

.faqs dt:first-of-type {
  border: none;
}

.faqs dd {
  padding: 0 25px 10px;
  font: 15px/1.5 "Raleway", Arial, Helvetica, sans-serif;
}

/* 18 ERROR PAGE */
.error .content {
  padding-top: 70px;
}

.error h2 {
  font-size: 35px;
}

/*19 LOGIN, REGISTER, MY ACCOUNT */
.modal {
  float: none;
  margin: 0 auto;
}

.single .box fieldset {
  border-top: 1px solid #DFDFD0;
  padding: 30px 0 0;
  margin-top: -5px;
}

.single .box label {
  color: #858585;
}

.history h6 {
  border-bottom: 1px solid #DFDFD0;
  padding: 0 0 20px;
  margin: 0 0 20px;
}

.history small {
  font-size: 13px;
  text-transform: lowercase;
}

.history .row {
  text-transform: uppercase;
  /*color: #858585;*/
  margin: 0;
}

.history .row div {
  padding: 0 0 20px;
  /*border-bottom: 1px solid #DFDFD0;*/
  margin: 0 0 20px;
}

.history .row p {
  padding: 0;
}

.history .row span {
  padding-right: 5px;
}

.history .price {
  font-size: 25px;
  padding-bottom: 12px;
}

/* 20 SIDEBAR */
.sidebar .widget {
  float: left;
  width: 100%;
  background: #fff;
  color: #34394A;
  -webkit-box-shadow: inset 0 0 0 1px #DFDFD0;
  -moz-box-shadow: inset 0 0 0 1px #DFDFD0;
  box-shadow: inset 0 0 0 1px #DFDFD0;
  margin: 0 0 30px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.sidebar .widget h4 {
  font-size: 15px;
  text-transform: uppercase;
  background: #31303f;
  color: #fff;
  padding: 17px 20px 16px;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}

.sidebar .widget h5 {
  font-family: "Raleway", Arial, Helvetica, sans-serif;
  font-weight: 700;
  padding: 0 0 6px;
}

.sidebar .widget img {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.sidebar .widget img:last-of-type {
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
}

.sidebar .widget .textwidget {
  padding: 17px 20px 5px;
}

.sidebar .widget form {
  float: left;
  width: 100%;
  padding: 17px 20px 0;
}

.sidebar .widget .f-row {
  padding-bottom: 20px;
}

.sidebar .widget .f-row input {
  padding: 10px 12px;
}

.contact-data {
  font-size: 17px;
  position: relative;
  padding: 10px 0 10px 35px;
  white-space: nowrap;
}

.contact-data .icon {
  position: absolute;
  top: 9px;
  left: 0;
}

.categories {
  margin: 0;
}

.categories li {
  text-transform: uppercase;
  font-size: 14px;
  border-bottom: 1px solid #DFDFD0;
  padding: 0;
}

.categories li:before {
  display: none;
}

.categories li div {
  display: inline-block;
  width: 100%;
  color: #34394A;
  padding: 17px 20px 16px;
  cursor: pointer;
}

.categories li:last-child {
  border-bottom: none;
}

.categories li.active {
  font-size: 15px;
}

.categories li.active div,
.categories li:hover div {
  background: #31303f;
  color: #fff;
}

.categories li.active:after {
  content: "";
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
}

.sidebar.right li.active:after {
  left: -15px;
  border-width: 27px 15px 27px 0;
  border-color: transparent #31303f transparent transparent;
}

.sidebar.left li.active:after {
  right: -15px;
  border-width: 27px 0 27px 15px;
  border-color: transparent transparent transparent #31303f;
}

.categories li.active:first-child div {
  -webkit-border-radius: 3px 0 0 0;
  -moz-border-radius: 3px 0 0 0;
  border-radius: 3px 0 0 0;
}

.categories li.active:last-child div {
  -webkit-border-radius: 0 0 0 3px;
  -moz-border-radius: 0 0 0 3px;
  border-radius: 0 0 0 3px;
}

/* 21 FOOTER */
.footer {
  float: left;
  width: 100%;
  min-height: 300px;
  color: #fff;
  padding-top: 33px;
}

.footer article {
  min-height: 170px;
}

.footer p {
  font-weight: 500;
}

.footer .contact-data a {
  color: #fff;
}

.social {
  display: inline-block;
  margin: 0;
}

.social li {
  text-align: center;
  float: left;
  display: block;
  margin: 0 6px 6px 0;
  padding: 0;
  border: none;
  width: 40px;
  height: 40px;
  background: #35394A;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.social li:before {
  display: none;
}

.social li:last-child {
  margin-right: 0;
}

.social li a {
  display: inline-block;
  width: 100%;
  height: 100%;
  color: #fff !important;
  font-size: 18px;
  line-height: 43px;
}

.copy {
  float: left;
  width: 100%;
  color: #727679;
  padding: 20px 0 0;
  border-top: 1px solid rgba(255, 255, 255, 0.07);
  -webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.5);
}

.copy p {
  float: left;
  max-width: 40%;
}

.copy a {
  color: #727679;
}

.copy a:hover,
.copy a:focus {
  color: #fff;
}

.foot-nav {
  float: right;
  max-width: 61%;
  font-weight: 500;
}

.foot-nav li {
  float: left;
  padding: 0 0 0 24px;
}

/* 22 FORM ELEMENTS*/
fieldset {
  display: inline-block;
  width: 100%;
}

label {
  display: inline-block;
  cursor: pointer;
  color: #31303f;
  position: relative;
  font-size: 13px;
  text-transform: uppercase;
  padding: 0 0 17px 1px;
}

label span {
  text-transform: none;
}

input, textarea {
  outline: none;
}

button, input, select, textarea {
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

button, input[type=button],
input[type=reset],
input[type=submit] {
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  -webkit-appearance: button;
  text-align: center;
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

input[type=search] {
  -webkit-appearance: textfield;
}

input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input[type=search],
input[type=email],
input[type=text],
input[type=tel],
input[type=number],
input[type=password],
textarea {
  display: block;
  padding: 12px 15px;
  font-size: 15px;
  width: 100%;
  border: 1px solid #DFDFD0;
  color: #34394A;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

input[type=text]:disabled, input[type=search]:disabled {
  color: darkgray !important;
}

input[type=submit]:disabled, .disabled-input {
  background-color: #2a3133 !important;
}

input[type=submit]:disabled:hover {
  background-color: #2a3133 !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

textarea {
  resize: none;
  height: 235px;
}

select {
  padding: 12px 15px;
  width: 100%;
  line-height: 1;
  color: #34394A;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #DFDFD0;
}

optgroup {
  font-style: normal;
  text-transform: uppercase;
  color: #31303f;
}

optgroup:before {
  content: attr(label);
  display: block;
  padding: 10px 0 5px;
}

option {
  font-size: 13px;
  color: #34394A;
}

input[type=text]:focus,
input[type=email]:focus,
input[type=number]:focus,
input[type=search]:focus,
input[type=password]:focus,
textarea:focus {
  border-color: #bbb;
}

input[type=file] {
  border: 1px solid #DFDFD0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color: #34394A;
  padding: 6px;
  margin: 5px 0;
}

.f-row {
  float: left;
  width: 100%;
  padding: 0 0 25px;
}

.f-row:last-child {
  padding-bottom: 0;
}

.f-row .full-width,
.f-row .one-half,
.f-row .one-third,
.f-row .two-third,
.f-row .one-fourth,
.f-row .three-fourth,
.f-row .one-fifth,
.f-row .one-sixth {
  padding: 0 30px 0 0;
}

.f-row div:last-child {
  padding-right: 0;
}

.f-title {
  margin: 35px 0 25px;
  padding: 17px 20px 16px;
  width: 100%;
  display: inline-block;
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.f-title:first-of-type {
  margin-top: 0;
}

.actions {
  float: left;
  width: 100%;
  padding: 29px 0 0;
  margin-top: 4px;
  border-top: 1px solid rgba(255, 255, 255, 0.6);
  -webkit-box-shadow: 0 -1px 0 #DFDFD0;
  -moz-box-shadow: 0 -1px 0 #DFDFD0;
  box-shadow: 0 -1px 0 #DFDFD0;
}

.actions .btn {
  margin-right: 26px;
}

.actions .btn.right {
  margin-right: 0;
}

.check, .radio {
  padding: 0 0 25px !important;
}

.check label,
.radio label {
  padding: 3px 0 0;
  float: left;
  max-width: 87%;
}

.one-sixth label {
  white-space: nowrap;
}

.error_message {
  background: #de2222;
  color: #fff;
  padding: 15px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  text-transform: uppercase;
  margin: 0 0 15px;
}

#message h6 {
  padding-bottom: 10px;
  color: #31303f;
}

#message p {
  padding: 0;
}

/* 23 PRELOADER */
.preloader {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
}

#followingBallsG {
  position: relative;
  width: 256px;
  height: 20px;
  top: 45%;
  left: 43%;
}

.followingBallsG {
  background-color: #31303f;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  -moz-border-radius: 10px;
  -moz-animation-name: bounce_followingBallsG;
  -moz-animation-duration: 2.3s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-direction: linear;
  -webkit-border-radius: 10px;
  -webkit-animation-name: bounce_followingBallsG;
  -webkit-animation-duration: 2.3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: linear;
  -ms-border-radius: 10px;
  -ms-animation-name: bounce_followingBallsG;
  -ms-animation-duration: 2.3s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-direction: linear;
  -o-border-radius: 10px;
  -o-animation-name: bounce_followingBallsG;
  -o-animation-duration: 2.3s;
  -o-animation-iteration-count: infinite;
  -o-animation-direction: linear;
  border-radius: 10px;
  animation-name: bounce_followingBallsG;
  animation-duration: 2.3s;
  animation-iteration-count: infinite;
  animation-direction: linear;
}

#followingBallsG_1 {
  -moz-animation-delay: 0s;
  -webkit-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}

#followingBallsG_2 {
  -moz-animation-delay: 0.23s;
  -webkit-animation-delay: 0.23s;
  -ms-animation-delay: 0.23s;
  -o-animation-delay: 0.23s;
  animation-delay: 0.23s;
}

#followingBallsG_3 {
  -moz-animation-delay: 0.46s;
  -webkit-animation-delay: 0.46s;
  -ms-animation-delay: 0.46s;
  -o-animation-delay: 0.46s;
  animation-delay: 0.46s;
}

#followingBallsG_4 {
  -moz-animation-delay: 0.69s;
  -webkit-animation-delay: 0.69s;
  -ms-animation-delay: 0.69s;
  -o-animation-delay: 0.69s;
  animation-delay: 0.69s;
}

@-moz-keyframes bounce_followingBallsG {
  0% {
    left: 0px;
    background-color: #31303f;
  }
  50% {
    left: 236px;
    background-color: #fff;
  }
  100% {
    left: 0px;
    background-color: #31303f;
  }
}
@-webkit-keyframes bounce_followingBallsG {
  0% {
    left: 0px;
    background-color: #31303f;
  }
  50% {
    left: 236px;
    background-color: #fff;
  }
  100% {
    left: 0px;
    background-color: #31303f;
  }
}
@-ms-keyframes bounce_followingBallsG {
  0% {
    left: 0px;
    background-color: #31303f;
  }
  50% {
    left: 236px;
    background-color: #fff;
  }
  100% {
    left: 0px;
    background-color: #31303f;
  }
}
@-o-keyframes bounce_followingBallsG {
  0% {
    left: 0px;
    background-color: #31303f;
  }
  50% {
    left: 236px;
    background-color: #fff;
  }
  100% {
    left: 0px;
    background-color: #31303f;
  }
}
@keyframes bounce_followingBallsG {
  0% {
    left: 0px;
    background-color: #31303f;
  }
  50% {
    left: 236px;
    background-color: #fff;
  }
  100% {
    left: 0px;
    background-color: #31303f;
  }
}
/* 24 PLUGINS */
/* Uniform */
/* General settings */
div.selector, div.selector span, div.checker span, div.radio span, div.uploader, div.uploader span.action, div.button, div.button span {
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

div.selector, div.checker, div.button, div.radio, div.uploader {
  display: -moz-inline-box;
  display: inline-block;
  *display: inline;
  zoom: 1;
  vertical-align: middle;
}

div.selector:focus, div.checker:focus, div.button:focus, div.radio:focus, div.uploader:focus {
  outline: 0;
}

div.selector, div.selector *, div.radio, div.radio *, div.checker, div.checker *, div.uploader, div.uploader *, div.button, div.button * {
  cursor: pointer;
  margin: 0;
  padding: 0;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

/* Input & Textarea */
input.uniform-input, select.uniform-multiselect, textarea.uniform {
  padding: 12px 15px;
  border: 1px solid #DFDFD0;
}

input.uniform-input.active, select.uniform-multiselect.active, textarea.uniform.active,
input.uniform-input.focus, select.uniform-multiselect.focus, textarea.uniform.focus {
  border-color: #bbb;
}

/* Remove default webkit and possible mozilla .search styles.
 * Keeping this as :active to remove browser styles */
div.checker input,
input[type=search],
input[type=search]:active {
  -moz-appearance: none;
  -webkit-appearance: none;
}

/* Select */
div.selector {
  border: 1px solid #DFDFD0;
  line-height: 48px;
  height: 48px;
  position: relative;
  overflow: hidden;
  width: 100% !important;
  padding-left: 15px;
  background: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

div.selector span {
  position: relative;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  height: 48px;
  line-height: 48px;
  background: #fff;
  cursor: pointer;
  width: 100% !important;
  padding-right: 30px;
}

div.selector span:before {
  content: "";
  position: absolute;
  top: 21px;
  right: 15px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 7px 0 7px;
  border-color: #ccc transparent transparent transparent;
}

div.selector select {
  opacity: 0;
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  border: none;
  background: none;
  position: absolute;
  height: 48px;
  top: 0;
  left: 0;
  width: 100%;
}

div.selector.active,
div.selector.focus {
  border-color: #bbb;
}

/* Checkbox */
div.checker {
  position: relative;
  top: 0;
  margin-right: 15px;
  float: left;
}

div.checker,
div.checker span,
div.checker input {
  width: 25px;
  height: 25px;
}

div.checker span {
  display: -moz-inline-box;
  display: inline-block;
  *display: inline;
  zoom: 1;
  text-align: center;
  background: #fff;
  border: 1px solid #DFDFD0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  position: relative;
}

div.checker span.checked:before {
  content: "✓";
  position: absolute;
  top: 1px;
  left: 5px;
  font-weight: bold;
  text-shadow: 0 1px 0 #31303f;
}

div.checker input {
  opacity: 0;
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  border: none;
  background: none;
  display: -moz-inline-box;
  display: inline-block;
  *display: inline;
  zoom: 1;
}

/* Radio */
div.radio {
  position: relative;
  top: 0;
  margin-right: 15px;
  float: left;
}

div.radio,
div.radio span,
div.radio input {
  width: 25px;
  height: 25px;
}

div.radio span {
  border: 1px solid #DFDFD0;
  background: #fff;
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  border-radius: 13px;
  position: relative;
  display: -moz-inline-box;
  display: inline-block;
  *display: inline;
  zoom: 1;
  text-align: center;
}

div.radio span.checked:before {
  content: "";
  width: 11px;
  height: 11px;
  background: #31303f;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  position: absolute;
  top: 6px;
  left: 6px;
}

div.radio input {
  opacity: 0;
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  border: none;
  background: none;
  display: -moz-inline-box;
  display: inline-block;
  *display: inline;
  zoom: 1;
  text-align: center;
}

/* Date & Time Picker */
.xdsoft_datetimepicker {
  box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.506);
  background: #FFFFFF;
  border-bottom: 1px solid #BBBBBB;
  border-left: 1px solid #CCCCCC;
  border-right: 1px solid #CCCCCC;
  border-top: 1px solid #CCCCCC;
  color: #333333;
  padding: 7px 10px 10px 0;
  position: absolute;
  z-index: 9999;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: none;
}

.xdsoft_datetimepicker iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 75px;
  height: 210px;
  background: transparent;
  border: none;
}

.xdsoft_datetimepicker table {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.xdsoft_datetimepicker button {
  border: none;
}

.xdsoft_noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.xdsoft_noselect::selection {
  background: transparent;
}

.xdsoft_noselect::-moz-selection {
  background: transparent;
}

.xdsoft_datetimepicker.xdsoft_inline {
  display: inline-block;
  position: static;
  box-shadow: none;
}

.xdsoft_datetimepicker * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

.xdsoft_datetimepicker .xdsoft_datepicker, .xdsoft_datetimepicker .xdsoft_timepicker {
  display: none;
}

.xdsoft_datetimepicker .xdsoft_datepicker.active, .xdsoft_datetimepicker .xdsoft_timepicker.active {
  display: block;
}

.xdsoft_datetimepicker .xdsoft_datepicker {
  width: 300px;
  float: left;
  margin-left: 10px;
}

.xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_datepicker {
  width: 256px;
}

.xdsoft_datetimepicker .xdsoft_timepicker {
  width: 58px;
  float: left;
  text-align: center;
  margin-left: 10px;
  margin-top: 0px;
}

.xdsoft_datetimepicker .xdsoft_datepicker.active + .xdsoft_timepicker {
  margin-top: 16px;
  margin-bottom: 3px;
}

.xdsoft_datetimepicker .xdsoft_mounthpicker {
  position: relative;
  text-align: center;
  padding-bottom: 1px;
  display: inline-block;
  width: 100%;
}

.xdsoft_datetimepicker .xdsoft_label i,
.xdsoft_datetimepicker .xdsoft_prev,
.xdsoft_datetimepicker .xdsoft_next,
.xdsoft_datetimepicker .xdsoft_today_button {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAAeCAYAAADaW7vzAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Q0NBRjI1NjM0M0UwMTFFNDk4NkFGMzJFQkQzQjEwRUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6Q0NBRjI1NjQ0M0UwMTFFNDk4NkFGMzJFQkQzQjEwRUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDQ0FGMjU2MTQzRTAxMUU0OTg2QUYzMkVCRDNCMTBFQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDQ0FGMjU2MjQzRTAxMUU0OTg2QUYzMkVCRDNCMTBFQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PoNEP54AAAIOSURBVHja7Jq9TsMwEMcxrZD4WpBYeKUCe+kTMCACHZh4BFfHO/AAIHZGFhYkBBsSEqxsLCAgXKhbXYOTxh9pfJVP+qutnZ5s/5Lz2Y5I03QhWji2GIcgAokWgfCxNvcOCCGKqiSqhUp0laHOne05vdEyGMfkdxJDVjgwDlEQgYQBgx+ULJaWSXXS6r/ER5FBVR8VfGftTKcITNs+a1XpcFoExREIDF14AVIFxgQUS+h520cdud6wNkC0UBw6BCO/HoCYwBhD8QCkQ/x1mwDyD4plh4D6DDV0TAGyo4HcawLIBBSLDkHeH0Mg2yVP3l4TQMZQDDsEOl/MgHQqhMNuE0D+oBh0CIr8MAKyazBH9WyBuKxDWgbXfjNf32TZ1KWm/Ap1oSk/R53UtQ5xTh3LUlMmT8gt6g51Q9p+SobxgJQ/qmsfZhWywGFSl0yBjCLJCMgXail3b7+rumdVJ2YRss4cN+r6qAHDkPWjPjdJCF4n9RmAD/V9A/Wp4NQassDjwlB6XBiCxcJQWmZZb8THFilfy/lfrTvLghq2TqTHrRMTKNJ0sIhdo15RT+RpyWwFdY96UZ/LdQKBGjcXpcc1AlSFEfLmouD+1knuxBDUVrvOBmoOC/rEcN7OQxKVeJTCiAdUzUJhA2Oez9QTkp72OTVcxDcXY8iKNkxGAJXmJCOQwOa6dhyXsOa6XwEGAKdeb5ET3rQdAAAAAElFTkSuQmCC);
}

.xdsoft_datetimepicker .xdsoft_label i {
  opacity: 0.5;
  background-position: -92px -19px;
  display: inline-block;
  width: 9px;
  height: 20px;
  vertical-align: middle;
}

.xdsoft_datetimepicker .xdsoft_prev {
  float: left;
  background-position: -20px 0px;
}

.xdsoft_datetimepicker .xdsoft_today_button {
  float: left;
  background-position: -70px 0px;
  margin-left: 5px;
}

.xdsoft_datetimepicker .xdsoft_next {
  float: right;
  background-position: 0px 0px;
}

.xdsoft_datetimepicker .xdsoft_next,
.xdsoft_datetimepicker .xdsoft_prev,
.xdsoft_datetimepicker .xdsoft_today_button {
  background-color: transparent;
  background-repeat: no-repeat;
  border: 0px none currentColor;
  cursor: pointer;
  display: block;
  height: 30px;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  outline: medium none currentColor;
  overflow: hidden;
  padding: 0px;
  position: relative;
  text-indent: 100%;
  white-space: nowrap;
  width: 20px;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_prev,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_next {
  float: none;
  background-position: -40px -15px;
  height: 15px;
  width: 30px;
  display: block;
  margin-left: 14px;
  margin-top: 7px;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_prev {
  background-position: -40px 0px;
  margin-bottom: 7px;
  margin-top: 0px;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box {
  height: 257px;
  overflow: hidden;
  border-bottom: 1px solid #DFDFD0;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div {
  background: #F5F5F5;
  border-top: 1px solid #DFDFD0;
  color: #34394a;
  font-size: 14px;
  text-align: center;
  border-collapse: collapse;
  cursor: pointer;
  border-bottom-width: 0px;
  height: 40px;
  line-height: 40px;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:first-child {
  border-top-width: 0px;
}

.xdsoft_datetimepicker .xdsoft_today_button:hover,
.xdsoft_datetimepicker .xdsoft_next:hover,
.xdsoft_datetimepicker .xdsoft_prev:hover {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.xdsoft_datetimepicker .xdsoft_label {
  display: inline;
  position: relative;
  z-index: 9999;
  margin: 0;
  padding: 5px 3px;
  font-size: 14px;
  line-height: 20px;
  background-color: #fff;
  float: left;
  width: 182px;
  text-align: center;
  cursor: pointer;
}

.xdsoft_datetimepicker .xdsoft_label:hover {
  text-decoration: underline;
}

.xdsoft_datetimepicker .xdsoft_label:hover i {
  opacity: 1;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select {
  border: 1px solid #DFDFD0;
  position: absolute;
  right: 0px;
  top: 30px;
  z-index: 101;
  display: none;
  background: #fff;
  max-height: 160px;
  overflow-y: hidden;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select.xdsoft_monthselect {
  right: -7px;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select.xdsoft_yearselect {
  right: 2px;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover {
  color: #fff;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option {
  padding: 5px 10px 5px 5px;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current {
  color: #fff;
  font-weight: 700;
}

.xdsoft_datetimepicker .xdsoft_month {
  width: 100px;
  text-align: right;
}

.xdsoft_datetimepicker .xdsoft_calendar {
  clear: both;
}

.xdsoft_datetimepicker .xdsoft_year {
  width: 48px;
  margin-left: 5px;
}

.xdsoft_datetimepicker .xdsoft_calendar table {
  border-collapse: collapse;
  width: 100%;
}

.xdsoft_datetimepicker .xdsoft_calendar th {
  height: 40px;
}

.xdsoft_datetimepicker .xdsoft_calendar td,
.xdsoft_datetimepicker .xdsoft_calendar th {
  width: 14.2857142%;
  background: #F5F5F5;
  border: 1px solid #DFDFD0;
  color: #34394a;
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
  padding: 0px;
  border-collapse: collapse;
  cursor: pointer;
  height: 40px;
}

.xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_calendar td,
.xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_calendar th {
  width: 12.5%;
}

.xdsoft_datetimepicker .xdsoft_calendar th {
  font-size: 12px;
  background: #31303f;
  border-color: #31303f;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default,
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
  color: #fff;
  font-weight: 700;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_other_month,
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_disabled,
.xdsoft_datetimepicker .xdsoft_time_box > div > div.xdsoft_disabled {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_other_month.xdsoft_disabled {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
}

.xdsoft_datetimepicker .xdsoft_calendar td:hover,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
  color: #fff;
  box-shadow: none;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_disabled:hover,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_disabled:hover {
  color: inherit !important;
  background: inherit;
  box-shadow: inherit;
}

.xdsoft_datetimepicker .xdsoft_calendar th {
  text-align: center;
  color: #fff;
  cursor: default;
}

.xdsoft_datetimepicker .xdsoft_copyright {
  color: #ccc;
  font-size: 10px;
  clear: both;
  float: none;
  margin-left: 8px;
}

.xdsoft_datetimepicker .xdsoft_copyright a {
  color: #eee;
}

.xdsoft_datetimepicker .xdsoft_copyright a:hover {
  color: #aaa;
}

.xdsoft_time_box {
  position: relative;
  border: 1px solid #DFDFD0;
}

.xdsoft_scrollbar > .xdsoft_scroller {
  background: #ccc;
  height: 20px;
  border-radius: 3px;
}

.xdsoft_scrollbar {
  position: absolute;
  width: 7px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  cursor: pointer;
}

.xdsoft_scroller_box {
  position: relative;
}

/* Slicknav Mobile Menu Core Style */
.slicknav_menu {
  display: none;
}

.slicknav_btn {
  position: relative;
  display: block;
  vertical-align: middle;
  float: right;
  padding: 27px 4% 30px 0;
  line-height: 1.125em;
  cursor: pointer;
}

.slicknav_menu .slicknav_menutxt {
  display: block;
  line-height: 1.188em;
  float: left;
}

.slicknav_menu .slicknav_icon {
  float: left;
  margin: 5px 0;
}

.slicknav_menu .slicknav_no-text {
  margin: 0;
}

.slicknav_menu .slicknav_icon-bar {
  display: block;
  width: 40px;
  height: 4px;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}

.slicknav_btn .slicknav_icon-bar + .slicknav_icon-bar {
  margin-top: 5px;
}

.slicknav_nav {
  clear: both;
}

.slicknav_nav ul,
.slicknav_nav li {
  display: block;
}

.slicknav_nav .slicknav_arrow {
  font-size: 0.8em;
  margin: 0 0 0 0.4em;
}

.slicknav_nav .slicknav_item {
  cursor: pointer;
}

.slicknav_nav .slicknav_row {
  display: block;
}

.slicknav_nav a {
  display: block;
}

.slicknav_nav .slicknav_item a,
.slicknav_nav .slicknav_parent-link a {
  display: inline;
}

.slicknav_menu:before,
.slicknav_menu:after {
  content: " ";
  display: table;
}

.slicknav_menu:after {
  clear: both;
}

.slicknav_menu {
  *zoom: 1;
}

.slicknav_menu {
  font-size: 16px;
  float: right;
  width: 100%;
}

.slicknav_nav {
  background: #fff;
  color: #858585;
  margin: 0;
  padding: 0;
  font-size: 0.875em;
}

.slicknav_nav, .slicknav_nav ul {
  list-style: none;
  overflow: hidden;
}

.slicknav_nav ul {
  padding: 0;
  margin: 0;
}

.slicknav_nav li {
  padding: 0;
  text-transform: uppercase;
}

.slicknav_nav .slicknav_row {
  padding: 15px 4% 13px;
}

.slicknav_nav a {
  padding: 15px 4% 13px;
  text-decoration: none;
  color: #858585;
  border-top: 1px solid #dfdfd0;
}

.slicknav_nav .slicknav_item a,
.slicknav_nav .slicknav_parent-link a {
  padding: 0;
  margin: 0;
  border: none;
}

.slicknav_nav .slicknav_row:hover,
.slicknav_nav li:hover a {
  color: #31303f;
}

.slicknav_nav .slicknav_txtnode {
  margin-left: 15px;
}

.slicknav_nav h2 {
  display: none;
}

.slicknav_nav div {
  padding: 0;
  /*width: 100%;*/
  float: none;
}

/* 25 PRINT CSS*/
@media print {
  * {
    color: #000;
    text-shadow: none;
    background: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-size: 10pt;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100%;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  select {
    background: none;
  }
  .header, .main-nav, .site-title, .sidebar, .footer {
    display: none;
  }
}
/* 26 Responsiveness */
@media screen and (max-width: 1200px) {
  .wrap {
    width: 94%;
  }
  .main-nav ul > li {
    padding-left: 25px;
  }
  .hentry .entry-content p {
    height: 47px;
    overflow: hidden;
  }
  .single .entry-content p {
    height: auto;
  }
  .advanced-search .form-group.spinner {
    width: 45%;
  }
  .advanced-search .form-group.spinner label {
    width: auto;
    line-height: 47px;
    padding: 0 30px 0 0;
  }
  .advanced-search .form-group.spinner input {
    width: 16%;
    float: left;
  }
  .advanced-search .form-group.radios {
    padding-left: 0;
    width: 30%;
  }
  .advanced-search .form-group.right {
    width: 25%;
    padding: 0;
  }
  .form-group.radios > div:last-of-type {
    float: left;
    padding-left: 30px;
  }
  .services.iconic .one-third {
    width: 50%;
  }
  .result .one-fourth:first-of-type {
    width: 25%;
  }
  .result .one-fourth {
    width: 25%;
  }
  .result .one-half {
    width: 50%;
  }
  .main-nav li > div {
    width: 100% !important;
  }
  .tab-content .one-fourth {
    width: 50%;
  }
}
@media screen and (max-width: 1050px) {
  .header {
    height: auto;
    min-height: 80px;
  }
  .header .wrap {
    height: auto;
  }
  .logo {
    float: left;
    width: 100%;
    display: block;
    padding: 15px 0;
  }
  .main-nav {
    float: left;
    width: 100%;
    border-top: 1px solid #dfdfd0;
    padding: 15px 0;
  }
  .main-nav ul > li {
    padding: 0 25px 0 0;
  }
  .advanced-search .form-group.spinner,
  .advanced-search .form-group.radios {
    width: 66.6667%;
  }
  .advanced-search .form-group.radios {
    padding-top: 20px;
  }
  .advanced-search .form-group.right {
    width: 33.3333%;
  }
  .result {
    position: relative;
  }
  .result .one-fourth:first-of-type {
    width: 50%;
    left: 0;
    top: 0;
    overflow: hidden;
  }
  .result .one-fourth {
    width: 50%;
    position: absolute;
    top: 60px;
    left: 50%;
    height: auto !important;
  }
  .result .one-fourth > div {
    padding: 41px 30px 34px;
    border-bottom: 1px solid #dfdfd0;
  }
  .result .one-half {
    float: right;
    width: 50%;
    margin-top: 180px;
    height: auto !important;
    position: relative;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .result .one-half h3 {
    position: absolute;
    top: -180px;
  }
  .result .price, .result .meta {
    float: left;
  }
  .result .meta {
    padding: 19px 10px 0;
  }
  .result .btn {
    margin-top: 0;
    position: absolute;
    top: -20px;
    left: -92.5%;
    width: 86%;
  }
  .single .entry-featured,
  .keyvisual {
    height: auto !important;
  }
  .single .entry-featured img,
  .keyvisual img {
    position: static !important;
    width: 100% !important;
  }
  .copy p,
  .foot-nav {
    max-width: 100%;
    width: 100%;
    float: left;
    text-align: center;
  }
  .foot-nav li {
    padding: 0 5px !important;
    display: inline-block;
    float: none;
  }
  .home .boxed .one-fourth {
    width: 50%;
  }
}
@media screen and (max-width: 1050px) {
  .wrap {
    width: 92%;
  }
  .full-width,
  .three-fourth,
  .one-half,
  .one-third,
  .two-third,
  .one-fourth,
  .one-fifth,
  .one-sixth {
    width: 100%;
  }
  .header .wrap {
    width: 100%;
    max-width: 100%;
    display: block;
  }
  .logo {
    position: absolute;
    top: 0;
    left: 4%;
    display: block;
    width: auto;
  }
  .sidebar {
    padding-bottom: 0;
  }
  .f-row {
    padding: 0;
  }
  .f-row .full-width, .f-row .one-half, .f-row .one-third, .f-row .one-fourth, .f-row .one-fifth, .f-row .one-sixth {
    padding: 0 0 25px;
  }
  .sidebar .widget,
  .tabs li {
    width: 100% !important;
    margin-right: 0;
  }
  .tabs li:first-child a {
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
  }
  .categories li:after,
  .tabs li.active a:after {
    display: none;
  }
  .tabs li {
    border-right: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
  .footer article {
    min-height: 100%;
  }
  .footer .one-half {
    padding-bottom: 13px;
    margin-bottom: 30px;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.07);
    -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.07);
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.07);
  }
  .footer .one-fourth {
    width: 50%;
  }
  .hentry .entry-featured,
  .hentry .entry-content {
    width: 100%;
  }
  .hentry .entry-content p {
    height: auto;
  }
  .hentry .entry-featured img {
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
  }
  .advanced-search .form-group.spinner,
  .advanced-search .form-group.radios {
    width: 100%;
    padding: 0 0 30px;
    float: right;
  }
  .advanced-search .form-group.right {
    width: 100%;
    padding: 0 0 30px;
    float: left;
  }
  .cta {
    padding: 30px 0;
  }
  .cta .wrap {
    text-align: center;
  }
  .cta p {
    display: inline-block;
    width: 100%;
    padding: 0;
  }
  .cta .btn {
    margin: 20px auto 0;
    float: none !important;
  }
  .testimonials p {
    padding: 0 5% 30px;
  }
  .actions {
    margin: 0;
  }
  .actions .btn {
    width: 47.5%;
    margin: 0 4% 30px 0;
  }
  .actions .btn:nth-child(2n) {
    margin-right: 0;
  }
  .intro .actions .btn {
    width: auto;
  }
  .result .one-fourth,
  .result .one-half {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    height: auto;
    margin: 0;
  }
  .result .one-fourth:first-of-type {
    width: 100%;
    text-align: center;
  }
  .result .one-fourth:first-of-type img {
    margin: 0 auto;
    max-width: 99%;
  }
  .result .one-half {
    border-bottom: 1px solid #dfdfd0;
  }
  .result .one-half h3 {
    position: relative;
    top: 0;
  }
  .result .one-fourth > div {
    padding: 25px 30px 20px;
    border-bottom: none;
  }
  .result .price {
    padding-top: 5px;
  }
  .result .meta {
    padding-top: 24px;
  }
  .result .btn {
    position: static;
    top: 0;
    left: 0;
    float: right;
    width: 33.3333%;
  }
  .site-title .container {
    height: auto;
    display: inline-block;
    padding: 0 0 27px;
  }
  .site-title h1 {
    float: left;
    width: 100%;
    padding: 27px 0 0;
  }
  .breadcrumbs {
    float: left;
    width: 100%;
    padding: 0;
  }
  .microlocations .one-fourth {
    width: 50%;
  }
  .readonly .one-fourth {
    width: 33.3333%;
  }
  .readonly .three-fourth {
    width: 66.6667%;
  }
  .gmap {
    height: 300px;
  }
}
@media screen and (max-width: 800px) {
  .home .services .one-third,
  .home .services .one-fourth,
  .footer .one-fourth {
    width: 100%;
  }
  .footer {
    min-height: 100%;
    padding: 0;
  }
  .footer .wrap {
    text-align: center;
  }
  .footer article {
    padding: 35px 15px 20px;
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.07);
    -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.07);
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.07);
  }
  .animated {
    -webkit-animation-duration: 0s;
    animation-duration: 0s;
  }
  .intro .textwidget {
    /*padding-top: 40%;*/
  }
  .gmap {
    height: 200px;
  }
  .preloader {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .result .btn {
    width: 100%;
    margin-top: 25px;
  }
  .microlocations .one-fourth,
  .tab-content .one-fourth,
  .readonly .one-fourth,
  .readonly .three-fourth {
    width: 100%;
  }
  .readonly .one-fourth {
    padding-bottom: 0;
  }
  .twoway .wrap div {
    width: 100%;
    padding: 0 0 15px;
    margin: 0 0 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    /*-webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, .3);*/
    /*-moz-box-shadow: 0 1px 0 rgba(255, 255, 255, .3);*/
    /*box-shadow: 0 1px 0 rgba(255, 255, 255, .3);*/
  }
  .twoway .wrap div:last-child {
    padding: 0;
    margin: 0;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}
@media screen and (max-width: 500px) {
  .intro .textwidget {
    /*padding-top: 50%;*/
  }
  .intro .textwidget h1 {
    font-size: 45px;
  }
  .intro .textwidget h2 {
    font-size: 17px;
  }
  .intro .btn.large {
    font-size: 14px;
    width: 46%;
    margin: 0 1%;
  }
  .advanced-search .form-group.spinner input {
    width: 100%;
  }
  .gmap {
    height: 150px;
  }
}
@media screen and (max-width: 400px) {
  .intro .textwidget h1 {
    font-size: 40px;
  }
  .intro .btn.large {
    width: 100%;
    margin: 0 0 15px;
  }
  .xdsoft_datetimepicker {
    padding-right: 0;
    max-width: 100%;
  }
  .xdsoft_datetimepicker .xdsoft_datepicker {
    margin: 0;
    width: 100%;
  }
  .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box {
    height: 116px;
  }
}
::-moz-selection {
  background: #ed6f14;
  color: #fff;
}

::selection {
  background: #ed6f14;
  color: #fff;
}

html {
  -webkit-tap-highlight-color: #ed6f14;
}

a, .more, .services.boxed .details h4 a:hover, .services.iconic h3, .content a, .description a:hover, .description a:focus, .microlocations p a:hover, .microlocations p a:focus, .hentry .entry-content h2 a:hover, .hentry .entry-content h2 a:focus, .footer .contact-data a:hover, .footer .contact-data a:focus, .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today, .faqs .expanded, .faqs .expanded:after {
  color: #ed6f14;
}

.color, .pager a.current, .pager a:hover, .pager a:focus, table.hover tr:hover td, .tabs li.active a, .social li:hover, .data th, .tabs li a:hover, .tabs li a:focus, .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default, .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current, .xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current, .xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover, .slicknav_menu .slicknav_icon-bar {
  background-color: #ed6f14;
}

.xdsoft_datetimepicker .xdsoft_calendar td:hover, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
  background: #ed6f14 !important;
}

.btn:hover, .btn:focus {
  background: #ed6f14 !important;
  color: #fff !important;
}

.more:after, .single a:after {
  border-bottom: 2px dotted #ed6f14;
}

.tabs li.active a:after {
  border-color: #ed6f14 transparent transparent transparent;
}

blockquote i,
.testimonials i {
  color: #ed6f14;
  padding-right: 8px;
}

.icon {
  color: #ed6f14;
}

.MuiTabs-indicator {
  background-color: #ed6f14 !important;
}

.red {
  background-color: #34394A !important;
}

.main {
  min-height: calc(var(--vh, 1) * 100 - 380px);
}

.logo {
  background-image: url("../../images/briz_logo.png");
  background-position: center;
  height: 84px;
  width: 120px;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.autocompleteTextField {
  width: 100%;
  border: none;
  position: relative;
}
.autocompleteTextField input {
  border: none;
  color: rgba(0, 0, 0, 0.73);
  outline: none;
  height: 48px;
  text-align: left !important;
}
.autocompleteTextField ul {
  list-style-type: none !important;
  background: #fff;
  color: black !important;
  text-align: left !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  max-height: 300px !important;
  overflow: auto !important;
  z-index: 2000 !important;
  width: 100% !important;
  top: 85px;
}
.autocompleteTextField .airlineForm {
  top: 48px !important;
}
@media screen and (max-height: 495px) {
  .autocompleteTextField .dropdown-go-up-small-screen {
    top: -120px;
    max-height: 120px;
  }
}
.autocompleteTextField ul::before {
  content: "";
  z-index: 20000000000 !important;
}
.autocompleteTextField li {
  padding: 10px 5px;
  cursor: pointer;
  border-top: 1px solid lightgray;
  z-index: 20000000000 !important;
  display: flex;
  justify-content: flex-start;
  max-height: 500px;
  align-items: center;
}
.autocompleteTextField li img {
  margin-right: 10px;
}
.autocompleteTextField li:hover {
  text-decoration: underline;
  background-color: rgba(128, 128, 128, 0.2);
}
.autocompleteTextField li::before {
  content: "" !important;
}

.autocompleteTextField li:hover {
  text-decoration: underline;
  background-color: rgba(128, 128, 128, 0.2);
}

.invert {
  -webkit-filter: invert(100%);
}

.text-max-length {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.short-text-max-length {
  width: 100%;
}

.med-text-max-length {
  width: 100%;
}

@media screen and (max-width: 340px) {
  .med-text-max-length {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 230px;
  }
}
@media screen and (max-width: 340px) {
  .short-text-max-length {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 150px;
  }
}
.slide-in-appear {
  transform: translateY(-50%) scaleY(0) !important;
  transition: 500ms cubic-bezier(0, 1, 0.5, 1);
}
.slide-in-appear.slide-in-appear-active {
  transform: translateY(0%) scaleY(1) !important;
}

.slide-in-appear-done {
  display: block !important;
}

.slide-out-appear {
  transform: translateY(0%) scaleY(1) !important;
  transition: 500ms cubic-bezier(0, 1, 0.5, 1);
}
.slide-out-appear.slide-out-appear-active {
  transform: translateY(-50%) scaleY(0) !important;
}

.slide-out-appear-done {
  display: none !important;
}

.lottie-spinner {
  position: fixed;
  z-index: 2000000;
  height: 15em;
  width: 15em;
  overflow: visible;
  margin: auto;
  display: flex;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  flex-flow: column;
  justify-items: center;
  justify-content: space-between;
}
.lottie-spinner .spinner-container {
  max-height: 25px;
}
.lottie-spinner .spinner-container .spinner-item path {
  fill: whitesmoke;
}
.lottie-spinner .spinner-container .spinner-text {
  order: 2;
  position: relative;
  bottom: 80px;
  font-size: 1em;
  background: linear-gradient(#fff, #fff) left no-repeat, rgba(255, 255, 255, 0.6);
  background-size: 0% 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  animation: loading 2s infinite linear;
}
@media (max-width: 991px) {
  .lottie-spinner .spinner-container .spinner-text {
    bottom: 55px;
  }
}
@media (max-width: 779px) {
  .lottie-spinner .spinner-container .spinner-text {
    bottom: 42px;
  }
}
@media (max-width: 575px) {
  .lottie-spinner .spinner-container .spinner-text {
    bottom: 40px;
  }
}

@keyframes loading {
  100% {
    background-size: 100% 100%;
  }
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.6);
}

.airline_info_wrap {
  top: 20px;
  background: transparent;
  position: fixed;
  z-index: 5000;
  left: 0;
  width: 100%;
}

.airline_info_inner_wrap {
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
  padding: 20px 15px 0;
  height: 100vh;
}

.labelled_grp_input {
  margin-top: 5px;
  position: relative;
}

.labelled_grp_input label {
  position: absolute;
  top: 0;
  left: 0;
  width: 92px;
  font-size: 12px;
  background: #ed6f14;
  color: #fff;
  padding: 12px 9px;
  height: 100%;
  text-align: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.labelled_grp_input input {
  padding-left: 100px !important;
  width: 100%;
  background: #fff !important;
  border: 1px solid #ed6f14;
  border-radius: 4px !important;
}

.labelled_grp_input Select {
  padding-left: 100px !important;
  width: 100%;
  background: #fff !important;
  border: 1px solid #ed6f14;
  border-radius: 4px !important;
}

.labelled_grp_input input.error ~ label {
  background: #D73246 !important;
}

.labelled_grp_input input.error {
  border-color: #D73246 !important;
}

.airline_info_inner_wrap .btn {
  border-radius: 4px !important;
  text-transform: capitalize;
}

.airline_info_inner_wrap .btn.cancel_btn, .airline_info_inner_wrap .btn.cancel_btn:hover, .airline_info_inner_wrap .btn.cancel_btn:active, .airline_info_inner_wrap .btn.cancel_btn:focus, .cancel_btn.btn-default:not([disabled]):not(.disabled):active {
  background: #666 !important;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
}

@-webkit-keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

.button-wrapper {
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
  width: 100%;
}
.button-wrapper .proceed-button {
  flex-basis: 60%;
}
.button-wrapper .proceed-button input:hover {
  background: #ed6f14 !important;
}
.button-wrapper .proceed-button input:active {
  background: #ed6f14 !important;
}
.button-wrapper .proceed-button input:visited {
  background: #ed6f14 !important;
}
.button-wrapper .proceed-button input :focus {
  background: #ed6f14 !important;
}
.button-wrapper .cancel-btn {
  flex-basis: 30%;
}

.result img {
  position: relative;
  top: 40px;
}
@media (max-width: 1199px) {
  .result img {
    top: 0px;
  }
}

.MuiBox-root {
  padding: 20px !important;
}

.tipTable ul {
  margin: 0;
  display: table;
  width: 100%;
  height: 132px;
}

.btn.red:hover, .btn.red:focus, .btn.red:hover, .btn.red:focus {
  background: #de2222 !important;
  color: #fff !important;
}

.tipTable li:first-child {
  border-left: 1px solid #DFDFD0 !important;
}

.tipTable li:before {
  display: none;
}

.tipTable li {
  width: 25%;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  padding: 0;
  border-bottom: 1px solid #DFDFD0 !important;
  transition: 1s;
  cursor: pointer;
}
.tipTable li .active {
  background: black;
  color: white;
  border-left: 1px solid #DFDFD0 !important;
  border-right: 1px solid #DFDFD0 !important;
}

.tipTable li div {
  padding: 0;
  line-height: 1.2;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.5s;
  border-right: 1px solid #DFDFD0;
}

.tipTable li div input {
  border-top: none !important;
  border-bottom: none !important;
  border: none !important;
  text-align: center;
}
.tipTable li div input:active {
  border: none;
}
.tipTable li div input:focus {
  border: none;
}
.tipTable li div input:visited {
  border: none;
}

.tipTable li div input::placeholder {
  font-size: 12px;
}

.tipTable li:hover div:first-child {
  background: rgba(0, 0, 0, 0.75);
  color: white;
}

.tipTable h3 {
  border: 1px solid #DFDFD0;
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
}

.congestionFee {
  flex-basis: 100%;
  font-size: 10px;
  margin-left: 10px;
  color: #ed6f14;
}

.descriptionCheckBox {
  flex-basis: 100%;
  font-size: 10px;
}

.MuiTab-wrapper {
  font-family: "Montserrat", sans-serif !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #ed6f14 !important;
}

.PrivateTabIndicator-colorPrimary-67 {
  background-color: #ed6f14 !important;
}

.payInCashInfo {
  max-width: 200px !important;
  font-family: "Montserrat", sans-serif !important;
}

.cards_list_wrap li {
  position: relative;
}

.cards_list {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.cards_list:after {
  position: absolute;
  content: "";
  border-bottom: 1px solid #DFDFD0;
  width: 100%;
  transform: translateX(-50%);
  bottom: -10px;
  left: 50%;
}

.cards_list .cc_card_img {
  max-width: 65px;
  margin-right: 10px;
}

.cards_list .cc_details p {
  margin: 0;
  padding-bottom: 0;
  font-family: "Montserrat", sans-serif !important;
  font-size: 11px;
}

.no-credit-card-text {
  font-family: "Montserrat", sans-serif !important;
  font-size: 12px;
  max-width: 200px;
}

.credit-card-content-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.cards_list .cc_edit {
  max-width: 100px;
  text-align: center;
  position: absolute;
  right: 1rem;
  top: 15px;
  cursor: pointer;
  z-index: 1;
}

.cards_list .cc_edit img {
  max-width: 25px;
  width: 85%;
  display: block;
}

.cards_list .cc_edit span {
  text-transform: uppercase;
}

.credit-card-icon-container {
  display: flex;
  justify-content: space-between;
  width: 175px;
}
.credit-card-icon-container .credit-card-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
  flex-basis: 45%;
  text-align: center;
  transition: 0.5s;
}
.credit-card-icon-container .credit-card-icon:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 480px) {
  .cards_list {
    flex-direction: column !important;
  }
  .cards_list .credit-card-content-container {
    flex-direction: column !important;
    text-align: center;
  }
  .cards_list div {
    margin: 2px;
  }
  .cards_list input {
    margin-bottom: 10px;
  }
}
.ReactModal__Overlay {
  position: fixed;
  overFlow: hidden !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.ReactModal__Content {
  overflow: hidden !important;
}

.common_selectfield {
  display: block;
  font-size: 15px;
  width: 100%;
  height: 46px;
  border: 1px solid #DFDFD0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.country_code_sec_payments {
  min-width: 100px;
  border: 1px solid #DFDFD0;
  cursor: pointer;
}

.country_code_sec_payments-register {
  min-width: 100px;
  border: 1px solid #DFDFD0;
  cursor: pointer;
  height: 45px !important;
  border-radius: 3px;
}

.full-width-credit-card {
  float: left;
  width: 100%;
  padding: 0 15px 30px;
  max-width: 100%;
}

.three-fourth-credit-card {
  float: left;
  width: 75%;
  padding: 0 15px 30px;
  max-width: 100%;
}

.one-half-credit-card {
  float: left;
  width: 50%;
  padding: 0 15px 30px;
  max-width: 100%;
}

.one-third-credit-card {
  float: left;
  width: 33.33%;
  padding: 0 15px 30px;
  max-width: 100%;
}

.two-third-credit-card {
  float: left;
  width: 66.66%;
  padding: 0 15px 30px;
  max-width: 100%;
}

.one-fourth-credit-card {
  float: left;
  width: 25%;
  padding: 0 15px 30px;
  max-width: 100%;
}

.one-fifth-credit-card {
  float: left;
  width: 20%;
  padding: 0 15px 30px;
  max-width: 100%;
}

.one-sixth-credit-card {
  float: left;
  width: 16.66%;
  padding: 0 15px 30px;
  max-width: 100%;
}

@media screen and (max-width: 400px) {
  .full-width-credit-card,
  .three-fourth-credit-card,
  .one-half-credit-card,
  .one-third-credit-card,
  .two-third-credit-card,
  .one-fourth-credit-card,
  .one-fifth-credit-card,
  .one-sixth-credit-card {
    width: 100%;
  }
}
.flag-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.flag-image-wrapper div:first-child {
  margin-right: 5px;
}

.autoCharge-checkboxes-container {
  display: flex;
  justify-content: space-between;
}
.autoCharge-checkboxes-container .cc_fare input {
  opacity: 0;
  display: none;
}
.autoCharge-checkboxes-container .cc_fare_checked span {
  content: url("../../images/checkwhitesmall.png");
  width: 20px;
  display: block;
  height: 20px;
  background: #ed6f14;
}
.autoCharge-checkboxes-container .cc_fare {
  background: #fff;
  padding: 4px 2px;
  cursor: pointer;
  width: 50%;
}
.autoCharge-checkboxes-container .cc_fare .fs_xxsmall {
  font-size: 9px !important;
  margin: 0;
}
.autoCharge-checkboxes-container .cc_fare .grey-text {
  font-size: 14px;
  margin-bottom: 4px;
}
.autoCharge-checkboxes-container .cc_btn_group {
  width: 100%;
}
.autoCharge-checkboxes-container .cc_btn_group .btn {
  color: #ed6f14 !important;
  border: 1px solid #ed6f14;
  padding: 5px 10px;
  box-shadow: none !important;
}
.autoCharge-checkboxes-container .cc_btn_group .active {
  background-color: #ed6f14;
  color: #fff !important;
}
.autoCharge-checkboxes-container .cc_btn_group input {
  display: none;
}
.autoCharge-checkboxes-container .cc_tip_div {
  margin-top: 15px;
}
.autoCharge-checkboxes-container .cc_tip_div p {
  text-align: left;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 15px;
}
.autoCharge-checkboxes-container .cc_fare span {
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #ed6f14;
  border-radius: 30px;
  margin: 0 auto;
  margin: 0 auto 6px auto;
}
.autoCharge-checkboxes-container .cc_fare input {
  opacity: 0;
  display: none;
}
.autoCharge-checkboxes-container .add_cc_p {
  cursor: pointer;
}

.MuiAppBar-colorPrimary {
  background-color: #ed6f14 !important;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.MuiInputBase-input {
  width: 100% !important;
  padding: 6px 0 7px !important;
  font-size: 16px !important;
  color: #34394A !important;
  border-radius: 0 !important;
  border: none !important;
}

.css-2fg9ac-control {
  height: 47px !important;
  border-color: #DFDFD0 !important;
  box-shadow: 0 0 0 1px #DFDFD0 !important;
  padding: 0px !important;
}
.css-2fg9ac-control:focus {
  border-color: black !important;
  box-shadow: none !important;
}

#input-with-icon-grid::placeholder {
  text-transform: lowercase !important;
}

.MuiPaper-rounded {
  border-radius: 0 !important;
}

.MuiListItem-container {
  padding: 0px 0 10px 0px !important;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.search-input .MuiPaper-root {
  background-color: transparent !important;
}

.text-center {
  font-family: "Montserrat", sans-serif !important;
  text-align: center !important;
}

.text-left {
  font-family: "Montserrat", sans-serif !important;
  text-align: left !important;
}

.text-right {
  font-family: "Montserrat", sans-serif !important;
  text-align: right !important;
}

.remove_sec {
  position: absolute;
  color: #dc3545;
  right: 0;
  top: 0;
  width: 70px;
  height: 45px;
  align-items: center;
  display: flex;
  text-align: center;
  justify-content: center;
  z-index: 0;
  cursor: pointer;
}

.remove_sec_outer_layer {
  position: relative;
  z-index: 1;
  background: #fff;
  transition: all 0.3s linear;
}

.move_left {
  transform: translateX(-70px);
  transition: all 0.3s linear;
}

.edit-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  font-size: 0.8rem;
  color: #22cc8c !important;
  cursor: pointer;
}

.prevent-text-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.add_cc {
  font-size: 26px;
  position: absolute;
  right: 0;
  padding: 3px 14px;
  cursor: pointer;
}

.cc_fare {
  background: #fff;
  padding: 4px 2px;
  cursor: pointer;
  width: 50%;
}

.cc_fare .fs_xxsmall {
  font-size: 9px !important;
  margin: 0;
}

.cc_fare .grey-text {
  font-size: 14px;
  margin-bottom: 4px;
}

.cc_btn_group {
  width: 100%;
}

.cc_btn_group .btn {
  color: #ed6f14 !important;
  border: 1px solid #ed6f14;
  padding: 5px 10px;
  box-shadow: none !important;
}

.cc_btn_group .btn:hover {
  background-color: #ed6f14 !important;
  color: #fff !important;
}

.cc_btn_group .active {
  background-color: #ed6f14;
  color: #fff !important;
}

.cc_btn_group input {
  display: none;
}

.cc_tip_div {
  margin-top: 15px;
}

.cc_tip_div p {
  text-align: left;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 15px;
}

.cc_fare span {
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #ed6f14;
  border-radius: 30px;
  margin: 0 auto;
  margin: 0 auto 6px auto;
}

.cc_fare input {
  opacity: 0;
  display: none;
}

.add_cc_p {
  cursor: pointer;
}

span.scan_icon {
  position: absolute;
  top: 2px;
  right: 8px;
  width: 40px;
  cursor: pointer;
}

.form-group.scan_sec input {
  padding-right: 55px;
}

.country_code_sec {
  background: #fff;
  text-align: left;
  position: relative;
  padding: 9px 9px 9px 45px;
  min-height: 39px;
}

.country_code_sec_payments {
  background: #fff;
  /*text-align: left;*/
  position: relative;
  padding: 0 15px;
  height: 47px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.country_code_sec_payments-register {
  background: #fff;
  position: relative;
  padding: 0 10px;
  height: 47px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.country_code_sec_payments-register div:last-child {
  padding-right: 10px !important;
}
@media (max-width: 779px) {
  .country_code_sec_payments-register {
    padding: 0 7px;
  }
  .country_code_sec_payments-register div:last-child {
    padding-right: 7px !important;
  }
}
@media (max-width: 575px) {
  .country_code_sec_payments-register {
    padding: 0 3px;
  }
  .country_code_sec_payments-register div:last-child {
    padding-right: 3px !important;
  }
}

.country_code_sec_payments-register .c_flag {
  max-width: 35px;
  margin-right: 10px;
}
@media (max-width: 779px) {
  .country_code_sec_payments-register .c_flag {
    margin-right: 7px;
  }
}
@media (max-width: 575px) {
  .country_code_sec_payments-register .c_flag {
    margin-right: 5px;
  }
}

.country_code_sec_payments-register .c_code {
  font-size: 14px;
  color: #34394A !important;
}
@media (max-width: 779px) {
  .country_code_sec_payments-register .c_code {
    font-size: 12px;
  }
}
@media (max-width: 575px) {
  .country_code_sec_payments-register .c_code {
    margin-right: 8px;
  }
}

.country_code_sec_payments .c_flag {
  max-width: 35px;
  margin-right: 10px;
}

.country_code_sec_payments .c_code {
  font-size: 14px;
  color: #34394A !important;
}

.ccode_wrap {
  min-width: 120px;
}

.country_code_sec .c_flag {
  max-width: 30px;
  position: absolute;
  left: 8px;
  top: 11px;
}

.country_code_sec .c_code {
  font-size: 14px;
}

.country_code_sec .c_flag.us_flag {
  top: 13px;
}

.css-1hwfws3 {
  padding: 0 8px !important;
  text-align: center !important;
}

.css-1okebmr-indicatorSeparator {
  margin: 0 !important;
  padding: 0 !important;
  background: none !important;
  width: 0 !important;
}

.css-tlfecz-indicatorContainer {
  padding: 0px !important;
}

.css-1sjyra8-control {
  padding: 0 !important;
  height: 47px !important;
  background: none !important;
}
.css-1sjyra8-control:focus {
  height: 47px !important;
}
.css-1sjyra8-control:active {
  height: 47px !important;
}

.css-1ol7sl6-control {
  min-height: 47px !important;
}

.css-9f8gmj-placeholder {
  color: #34394A !important;
}

.border-danger {
  border: 1px solid #dc3545 !important;
}

.alert-text {
  color: #dc3545 !important;
}

.alert-link {
  cursor: pointer;
  color: #dc3545 !important;
  text-transform: lowercase;
}
.alert-link:hover {
  text-decoration: underline;
}

.alert-link::first-letter {
  text-transform: uppercase !important;
}

.span-as-link {
  cursor: pointer;
  color: #ed6f14;
  text-transform: lowercase;
}
.span-as-link:hover {
  text-decoration: underline;
}

.span-as-link::first-letter {
  text-transform: uppercase !important;
}

.custom-modal-body {
  margin-top: 10px;
  padding: 0 48px;
}

.custom-small-modal-body {
  padding: 10px 48px;
}

.modal-full-height-overflow {
  height: calc(var(--vh, 1vh) * 80 - 90px);
  padding: 15px 30px;
  max-height: 710px;
  overflow: auto;
}

.modal-full-height {
  height: calc(var(--vh, 1vh) * 80 - 90px);
  max-height: 710px;
}

.trip-status {
  font-size: 16px;
  font-weight: 500;
}

.canceled {
  color: #dc3545 !important;
}

.completed {
  color: #22cc8c !important;
}

.main-color-text {
  color: #ed6f14 !important;
}
.main-color-text:hover {
  color: #31303f !important;
}

.trip-options-buttons-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.trip-options-buttons-container input {
  margin: 0 5px;
}

.text-image-row {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-basis: 100%;
  font-size: 15px !important;
  margin-bottom: 10px;
  align-items: end;
}
.text-image-row span {
  width: 90%;
}
.text-image-row img {
  width: 4%;
  margin-right: 5px;
  object-fit: contain;
}
@media (max-width: 991px) {
  .text-image-row img {
    width: 4%;
  }
}
@media (max-width: 779px) {
  .text-image-row img {
    width: 6%;
  }
}
@media (max-width: 575px) {
  .text-image-row img {
    width: 8%;
  }
}

.text-right {
  text-align: right !important;
}

.three-fourth-trip-details {
  float: left;
  width: 75%;
  max-width: 100%;
}

.one-fourth-trip-details {
  float: left;
  width: 25%;
  max-width: 100%;
}

.trip-details-badge {
  display: flex;
  justify-items: center;
  justify-content: flex-end;
}

.trip_num {
  border-right: 1px solid #DFDFD0;
}
.trip_num span {
  max-width: 100px;
}

.label-container {
  display: flex;
  flex-direction: column;
}

.one-half-full {
  float: left;
  width: 50%;
  padding: 0 15px 30px;
  max-width: 100%;
}
@media (max-width: 779px) {
  .one-half-full {
    font-size: 15px !important;
  }
}
@media (max-width: 575px) {
  .one-half-full {
    font-size: 13px !important;
  }
}

.one-third-full {
  float: left;
  width: 40%;
  max-width: 100%;
  padding-right: 10px;
  padding-bottom: 25px;
}
@media (max-width: 779px) {
  .one-third-full {
    font-size: 15px !important;
  }
}
@media (max-width: 575px) {
  .one-third-full {
    font-size: 13px !important;
    padding-right: 0px;
    width: 45%;
  }
}

.two-third-full {
  float: left;
  width: 60%;
  max-width: 100%;
  padding-left: 10px;
  padding-bottom: 25px;
}
@media (max-width: 779px) {
  .two-third-full {
    font-size: 15px !important;
    padding-left: 15px;
  }
}
@media (max-width: 575px) {
  .two-third-full {
    font-size: 13px !important;
    width: 55%;
  }
}

.one-half-full-button {
  float: left;
  width: 50%;
  padding: 0 15px 30px;
  max-width: 100%;
}
@media (max-width: 575px) {
  .one-half-full-button {
    width: 100%;
    padding: 0;
    margin-top: 3px;
  }
}

.one-third-full-button {
  float: left;
  width: 33.33%;
  padding: 0 15px 30px;
  max-width: 100%;
}
@media (max-width: 779px) {
  .one-third-full-button {
    width: 100%;
    padding: 0;
    margin-top: 3px;
  }
}

.one-half-trip-details-image {
  width: 42.5%;
}

.tip-and-tolls-container {
  display: flex;
  align-items: center;
}
.tip-and-tolls-container .tip-and-tolls-input-container {
  display: flex;
  align-items: center;
}
.tip-and-tolls-container .tip-and-tolls-input-container .extra-charge-currency {
  font-size: 20px !important;
  margin-right: 15px;
}
.tip-and-tolls-container .tip-and-tolls-input-container input {
  border-radius: 15px;
  text-align: center;
}

.logo-wrapper {
  padding: 0;
}

.logo {
  max-width: 280px;
}

.no-border {
  border: none;
}

.top-title {
  margin-top: 30px;
  font-family: CrimsonTextRegular, serif;
}

.app-store-name {
  color: #ed6f14;
}

.links-wrapper {
  width: 100%;
  max-width: 700px;
  height: 150px;
  margin: 0 auto;
}
@media (max-width: 991px) {
  .links-wrapper {
    flex-direction: column;
  }
}

.download-btn {
  border: 0 none;
  border-radius: 12px;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 70px;
}
@media (max-width: 991px) {
  .download-btn {
    min-height: 65px;
    margin: 10px 0;
  }
}
@media (max-width: 779px) {
  .download-btn {
    min-height: 60px;
  }
}

.ios-btn {
  background-image: url("../../images/appstore_download.png");
  background-position: center;
}

.android-btn {
  background-image: url("../../images/googleplay_download.png");
  background-position: center;
}

.ios-btn:hover, .android-btn:hover {
  text-decoration: none;
}

.trip-details-image {
  height: 130px;
  max-width: 190px;
  min-width: 110px;
  object-fit: contain;
  width: 100%;
}

.no-shadow {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.trip-details-title {
  margin-top: 5px;
}
.trip-details-title h3 {
  color: black;
}
@media (max-width: 575px) {
  .trip-details-title h3 {
    font-size: 17px !important;
  }
}
@media (max-width: 575px) {
  .trip-details-title h4 {
    font-size: 15px !important;
  }
}

.trip-no {
  display: flex;
  margin-bottom: 10px;
  justify-content: flex-start;
}
.trip-no div:first-child {
  margin-right: 5px;
}

.flex-col-space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.flex-row-space-between {
  display: flex;
  justify-content: space-between;
}

.rate_driver_form .img {
  margin: 0 auto;
}
.rate_driver_form ul.rating_images img {
  display: inline-block;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -ms-transition: 0.5s all;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  cursor: pointer;
  opacity: 0.6;
  width: 100%;
  display: block;
}
.rate_driver_form ul.rating_images li.selected img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
}
.rate_driver_form .rating_images {
  background: #fff;
  border-radius: 50px;
  padding: 10px;
  display: flex;
  text-align: center;
  justify-content: center;
  max-width: 300px;
  margin: 0 auto;
  border: 1px solid #fff;
}
.rate_driver_form .rating_images li:first-child {
  padding: 0;
}
.rate_driver_form .rating_images li {
  cursor: pointer;
}
@media (max-width: 991px) {
  .rate_driver_form .rating_images {
    width: 275px;
  }
}
@media (max-width: 575px) {
  .rate_driver_form .rating_images {
    width: 250px;
  }
}
@media (max-width: 419px) {
  .rate_driver_form .rating_images {
    width: 225px;
  }
}

.rate-driver-my-trips-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.rate-driver-my-trips-section .driver-name {
  font-size: 13px;
  color: darkgray;
}
.rate-driver-my-trips-section .rating_images {
  background: #fff;
  border-radius: 50px;
  padding: 10px;
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
  border: 1px solid #fff;
  width: 180px;
}
.rate-driver-my-trips-section .rating_images li:first-child {
  padding: 0;
}
.rate-driver-my-trips-section .rating_images li {
  cursor: pointer;
}
@media (max-width: 991px) {
  .rate-driver-my-trips-section .rating_images {
    width: 170px;
  }
}
@media (max-width: 575px) {
  .rate-driver-my-trips-section .rating_images {
    width: 160px;
  }
}
@media (max-width: 419px) {
  .rate-driver-my-trips-section .rating_images {
    width: 150px;
  }
}
.rate-driver-my-trips-section ul.rating_images img {
  display: inline-block;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -ms-transition: 0.5s all;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  cursor: pointer;
  opacity: 0.6;
  width: 100%;
  display: block;
}
.rate-driver-my-trips-section ul.rating_images li.selected img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
}

.referral_img {
  margin-bottom: 25px;
}

header .collpase-icon {
  display: none;
  float: right;
  font-size: 40px;
  margin: 10px;
}

.partners-images-container {
  display: flex;
  align-items: center;
}
.partners-images-container img {
  max-width: 190px;
}

.tip-table-padding {
  padding: 0 40px !important;
}

@media screen and (max-width: 1050px) {
  header .collpase-icon {
    display: flex;
    width: 90px;
    justify-content: space-between;
    align-items: center;
  }
  header .main-nav {
    padding: 0 !important;
  }
  header ul {
    display: flex;
    flex-direction: column;
    float: left !important;
    width: 100%;
  }
  header ul li {
    border-bottom: 1px solid #dfdfd0 !important;
  }
  header ul li a {
    display: block;
    color: #191F26;
    font-size: 13px;
    padding: 9px 15px 7px;
  }
  .partners-images-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .tip-table-padding {
    padding: 0 !important;
  }
}
@media screen and (min-width: 1051px) {
  header ul {
    float: right;
  }
}
.css-yk16xz-control, .css-1pahdxg-control {
  height: 47px !important;
  border-color: hsl(0, 0%, 80%) !important;
  box-shadow: none !important;
}

.image-container {
  background-position: center;
  border: 0 none;
  border-radius: 12px;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.reward-program-image-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 150px;
  transition: 0.5ms;
}
.reward-program-image-section .image-container {
  background-position: center;
  border: 0 none;
  border-radius: 12px;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.reward-program-image-section .reward_img {
  background-image: url("../../images/reward_img.png");
}
.reward-program-image-section .regular_img {
  background-image: url("../../images/carmel_points_logo.png");
}

.abt_img_div {
  width: 50%;
  margin: 10px auto;
}

.dropdown-image {
  width: 5%;
}

@media screen and (max-width: 1050px) {
  .dropdown-image {
    width: 2%;
  }
}
@media screen and (max-width: 800px) {
  .dropdown-image {
    width: 3%;
  }
}
@media screen and (max-width: 500px) {
  .dropdown-image {
    width: 4%;
  }
}
.intro {
  background: url(../../images/uploads/keyvisual.jpg) 50% 0 fixed no-repeat;
  background-size: cover;
  height: calc(var(--vh, 1vh) * 90 - 84px);
  overflow: hidden;
  float: left;
  max-height: 1280px;
  width: 100%;
  min-height: 800px;
  display: flex;
  align-items: center;
}

.intro .textwidget {
  color: #fff;
  text-align: center;
}

.intro .textwidget h1 {
  text-transform: uppercase;
  font-size: 61px;
  margin: 0 0 -5px;
}
@media (max-width: 991px) {
  .intro .textwidget h1 {
    font-size: 50px !important;
  }
}
@media (max-width: 575px) {
  .intro .textwidget h1 {
    font-size: 45px !important;
  }
}
@media (max-width: 419px) {
  .intro .textwidget h1 {
    font-size: 40px !important;
  }
}

.intro .textwidget h2 {
  text-transform: uppercase;
  font-size: 23px !important;
  margin: 0;
}
@media (max-width: 991px) {
  .intro .textwidget h2 {
    font-size: 20px !important;
  }
}
@media (max-width: 575px) {
  .intro .textwidget h2 {
    font-size: 18px !important;
  }
}
@media (max-width: 419px) {
  .intro .textwidget h2 {
    font-size: 16px !important;
  }
}

.intro .actions {
  margin: 6px 0 0;
  padding: 46px 0 0;
  background: url(../../images/separator.jpg) 50% 0 no-repeat;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.intro .actions .btn {
  margin: 0 10px;
}
@media (max-width: 575px) {
  .intro .actions .btn {
    margin: 10px 5px !important;
  }
}

.main-activity-trip-pick-form {
  display: block;
}
.main-activity-trip-pick-form .one-third {
  padding: 5px;
}
.main-activity-trip-pick-form .one-third:first-child {
  padding-left: 0px;
}
.main-activity-trip-pick-form .one-third:nth-child(3) {
  padding-right: 0px;
}

.how-many-people-input-field {
  display: flex;
  width: 45% !important;
  justify-content: flex-start;
}
@media (max-width: 575px) {
  .how-many-people-input-field {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

.quantity-input {
  display: flex;
  align-items: center;
  background: transparent;
  color: white;
  margin-left: 10px;
}

.quantity-input__modifier,
.quantity-input__screen {
  user-select: none;
  outline: none;
  background: transparent;
  color: white;
}

#people {
  width: 80px !important;
}

.quantity-input__modifier {
  font-size: 1.3rem;
  border: 0 solid #dbdbdb;
  text-align: center;
  cursor: pointer;
}
.quantity-input__modifier:hover {
  color: #555555;
}

.briz-drop-down-indicator {
  text-align: left;
}

.logout-icon {
  padding-top: 24px !important;
  cursor: pointer;
}
.logout-icon img {
  filter: invert(0%) contrast(0.1) brightness(0);
  transition: all 0.5s;
}

.airport-field-height {
  min-height: 100px;
}

/* Tooltip text */
.tooltiptext {
  visibility: hidden;
  width: 100px;
  opacity: 0;
  background-color: #31303f !important;
  color: #fff !important;
  text-align: center;
  padding: 4px 0;
  border-radius: 6px;
  transition: 0.5s;
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 1050px) {
  .main-activity-trip-pick-form {
    display: flex;
    flex-direction: column;
  }
  .main-activity-trip-pick-form .one-third {
    padding: 5px 0px;
  }
  .main-activity-trip-pick-form #order-1 {
    order: 1;
  }
  .main-activity-trip-pick-form #order-2 {
    order: 2;
  }
  .main-activity-trip-pick-form #order-3 {
    order: 3;
  }
  .main-activity-trip-pick-form #order-4 {
    order: 4;
  }
  .logout-icon {
    width: 45%;
    height: 45px;
    object-fit: contain;
    padding: 0 !important;
    filter: invert(0%) contrast(0.1) brightness(0);
    transition: all 0.5s;
  }
  .tooltip {
    width: 45%;
    height: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .tooltip img {
    width: 100%;
    object-fit: contain;
    padding: 0 !important;
    filter: invert(0%) contrast(0.1) brightness(0);
    transition: all 0.5s;
  }
  .tooltip .tooltiptext {
    font-size: 15px;
    right: 100px;
  }
  .how-many-people-input-field {
    width: 100% !important;
  }
  .hide-on-shrink {
    display: none !important;
  }
  .airport-field-height {
    min-height: 300px;
  }
}
.title-with-text-border {
  color: black;
  font-weight: bold;
  font-size: 28px;
}

.badge {
  padding: 4px 7px;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600;
  font-size: 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  color: white;
}

.primary {
  background: #22cc8c;
}

.secondary {
  background: #242933;
}

.warning {
  background: #dc3545;
}

.order-1 {
  flex-basis: 100%;
  order: 1;
}

.order-2 {
  flex-basis: 100%;
  order: 2;
}

.order-3 {
  flex-basis: 100%;
  order: 3;
}

.order-4 {
  flex-basis: 100%;
  order: 4;
}